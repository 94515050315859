<template>
  <v-container class="full-height-container px-6">
    <v-row>
      <v-col cols="12" class="d-flex align-center flex-column login-container">
        <v-img
          v-if="isLogoBase64 || !customLogo"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
          contain
          :src="customLogo ? customLogo : require('@/assets/logo.svg')"
          class="mb-10"
        ></v-img>
        <div v-else v-html="customLogo" class="mb-10 d-flex" 
               
            >
            </div>
        <v-card elevation="4" class="d-flex">
          <v-card-title class="d-flex justify-center">
            <h1 class="font-weight-800 text-center">{{ $t('heading.forgotPasswordPage.title') }}</h1>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" @submit.prevent>
              <v-text-field
                outlined
                :label="$t('form.label.emailAddress')"
                :rules="emailRules"
                v-model="emailForForgotPassword"
                large
                solo
                flat
                hide-details="auto"
                validate-on-blur
                @keydown.enter="sendPasswordResetLink"
              ></v-text-field>
              <v-btn
                large
                @click.prevent=""
                color="primary"
                block
                elevation="0"
                class="mt-6"
                @click="sendPasswordResetLink"
                >{{ $t('button.resetPasswordSend') }}
              </v-btn>
            </v-form>
            <div class="d-flex justify-center mt-6">
              <p>
                {{ $t('message.falseAlert') }}
                <a
                  href="/login"
                  class="href"
                  @click.prevent="$router.push({ path: '/login' })"
                  >{{ $t('button.signIn') }}</a
                >
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-img
      :src="require('@/assets/illustrations/radio-lines.svg')"
      class="radio-lines"
    />
  </v-container>
</template>

<script>
import Auth from "@/apis/Auth";

import FormRulesMixin from "../mixins/FormRulesMixin";
import Api from "@/apis/Api";

export default {
  mixins: [FormRulesMixin],
  data: function () {
    return {
      errors: [],
      loading: false,
      emailForForgotPassword: "",
    };
  },
  beforeRouteEnter: async (__, _, next) => {
    await Auth.remoteAuthCheck().then((response) => {
      if (response === true) {
        next((vm) => {
          vm.$root.$emit("loggedIn", true);
          next(vm.$route.query.redirect || "/");
        });
      } else {
        next();
      }
    });
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
    isLogoBase64: function() {
      return this.customLogo &&  this.customLogo.trim().startsWith('data:image/');
    }
  },
  methods: {
    handleEnter(e) {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        this.login();
      }
    },
    sendPasswordResetLink() {
      if (this.$refs.form.validate() === false) {
        return;
      }
      Api.post("/forgot-password", { email: this.emailForForgotPassword })
        .then((response) => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: response.data.message,
          });
          this.forgotPasswordEnabled = false;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: error.response.data.errors.email[0],
          });
        });
      this.emailForForgotPassword = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  z-index: 999;
}

.full-height-container {
  display: flex;
  align-items: center;
  height: 100vh;
}

.radio-lines {
  position: absolute;
  top: 0;
  left: 0;
}

.v-card::v-deep {
  max-width: 420px;
  width: 100%;

  .v-card {
    &__text,
    &__title {
      padding-left: 48px;
      padding-right: 48px;
    }

    &__text {
      overflow: visible;
      padding-bottom: 48px;
      padding-top: 0px;
      -webkit-line-clamp: unset !important;
    }

    &__title {
      padding-top: 32px;
      padding-bottom: 32px;
      word-break: break-word;
    }
  }

  .v-input.v-input--checkbox {
    .v-input__slot {
      min-height: unset;
      max-height: unset;
    }
  }
}

.form {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.v-input::v-deep .v-input__control .v-input__slot .v-input__prepend-inner {
  margin-top: 2px;
}

.login-error-message {
  background: linear-gradient(
    90deg,
    rgba(241, 40, 40, 0.06) 0%,
    rgba(241, 40, 40, 0) 33.43%
  );
  border-radius: 6px;
  border: 1px solid map-get($error, base);
  border-left-width: 4px;
  padding: 16px;
  display: flex;

  &__icon-box {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  &__text-box {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 8px;
    color: map-get($text, heading);
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .v-card::v-deep {
    .v-card {
      &__title,
      &__text {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}
</style>
